<template>
<div class=" wrapper" v-loading.fullscreen.lock="loading" :element-loading-text="$t('connecting')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="top-header">
        <h1 class="color-primary"> {{$t('investment_project')}} </h1>
    </div>
    <div class="wp-body obj-list">
        <header class="wp-header flex items-center justify-between gap-4">
            <div class="grid flex-1 grid-cols-12 gap-3">
                <div v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" class="col-span-2">
                    <el-select :placeholder="$t('region')" class="w-full"  v-model="region"  @change="regionChanged(); __GET(); " clearable>
                        <el-option v-for="item in regions" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.shared_id">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" class="col-span-2">
                    <el-select :placeholder="$t('district')" class="w-full"  v-model="district" @change="ChangeDistrict(), __GET()" :disabled="!region" clearable>
                        <el-option v-for="item in districts" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div v-if="['admin', 'org_admin', 'operator', 'ministry_admin'].includes(role)" class="col-span-2">
                    <el-select :placeholder="$t('building_category')" class="w-full"  @change="__GET()" v-model="buildinggroup" clearable>
                        <el-option v-for="item in building_group" :key="item.id" :label="item[`name_${$i18n.locale}`]? item[`name_${$i18n.locale}`]:item.name_cyr" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="col-span-2 flex gap-2 items-center">
                    <el-input class="flex-1" style="margin-left: 0 !important;" :placeholder="$t('search')" prefix-icon="el-icon-search" v-model="search" @keyup.enter.native="__GET()" clearable>
                    </el-input>
                    <el-button @click="__GET()" type="primary" icon="el-icon-search"  size="small" circle></el-button>
                </div>
                <div class="col-span-2">
                   
                </div>
                <div class="col-span-2"></div>
            </div>
            <button v-if="['admin'].includes(role)" class="button dark-blue" :disabled="loading_generate" @click="generate_building_list()">
                        <i class="el-icon-download mr-1" v-if="!loading_generate"></i>
                        <i class="el-icon-loading mr-1" v-else></i>
                        {{$t('download')}} </button> 
        </header>

        <el-table :data="buildsList" class="mt-5" style="width: 100%; margin-bottom: 20px;" row-key="id">
            <el-table-column label="№" width="50" align="center">
                <template slot-scope="scope">
                    {{ (current_page-1)*10 + (scope.$index + 1) }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('building_name')" prop="name_cyr" align="center" width="150" sortable>
                <template slot-scope="scope">
                    <button @click="routeTo(scope.row, 0)" class="table-link-button"> {{scope.row.name_cyr? scope.row.name_cyr: '***'}} </button>
                </template>
            </el-table-column>
            <el-table-column v-if="role == 'admin'" prop="cadastral_number" :label="$t('cadaster_number')" align="center" sortable> </el-table-column>
            <el-table-column :label="$t('building_address')" prop="address" align="center" sortable> </el-table-column>
            <el-table-column :label="$t('year_construction')" prop="year_construction" align="center" sortable></el-table-column>
            <el-table-column :label="$t('building_type')" prop="buildingtype"  align="center" sortable width="130px">
                <template slot-scope="scope">
                    <el-tag :class="buildingtype === 'NEW' ? 'new_build' : 'exist_build'" style="width: 120px; cursor: pointer" size="small" :type="buildingtype === 'NEW' ? 'success' : 'info'">{{ scope.row.buildingtype === 'NEW' ? $t('new_building') : $t('existing_building') }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('investment_project')" prop="is_invest"  align="center"  width="130px">
                <template slot-scope="scope">
                    <InvestmentSwitch v-model="scope.row.is_invest" :id="scope.row.id" :disabled="role!='operator'" />
                </template>
            </el-table-column>
            <el-table-column :label="$t('created_by')" prop="created_by"  align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.created_by)">
                        {{scope.row.created_by!=null && scope.row.created_by.profile!=null ? scope.row.created_by.profile.name+" "+scope.row.created_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('updated_by')" prop="updated_by" align="center" sortable>
                <template slot-scope="scope">
                    <div class="table-link-button" @click="UserModal(scope.row.updated_by)">
                        {{scope.row.updated_by!=null && scope.row.updated_by.profile!=null ? scope.row.updated_by.profile.name +" "+ scope.row.updated_by.profile.surname : '-' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="role == 'admin' ?  $t('passport_one')+' / '+$t('statistic'):$t('statistic')" width="180" align="center">
                <template slot-scope="scope">
                    <div class="flex justify-center">
                        <div v-if="role == 'admin'">
                            <el-button @click="GeneratePasport(scope.row.id)" class="el-button-download el-icon-s-order"> </el-button>
                        </div>
                        <el-button class="ml-2 el-button-download el-icon-download" size="small" @click="DownloadExel(scope.row.id, scope.row.name_cyr)"></el-button>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="id" :label="$t('change')" v-if="role!='admin' && role !== 'org_admin' && role!=='ministry_admin' ">
                <template slot-scope="scope">
                    <div class="d-flex justify-center" v-if="!(role=='operator' && scope.row.buildingtype == 'NEW')">
                        <el-button @click="routeTo(scope.row, '1')" size="small" class="el-icon-edit el-button-download "></el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="margin-left: auto; margin-top: auto" class="ml-auto" :page-sizes="[10, 20, 50, 100]" :page-size="5" @current-change="paginate" @size-change="paginateSize" :current-page.sync="current_page1" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
        <el-drawer :title="$t('building_passport')" :visible.sync="drawer" direction='rtl'>
            <div>
                <div class="pdf">
                    <vue-pdf ref="print" :src="pdf"></vue-pdf>
                    <div class="loading-pdf" v-show="loadDawer">
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
                <div class="w-full pdf-control">
                    <button class="button dark-yellow" @click="downloadFile(pdf)"><i class="el-icon-download mr-2"></i> {{$t('download')}}</button>
                    <a :href="pdf" target="_blank">
                        <button class="ml-3 button dark-blue"><i class="el-icon-view mr-2"></i> {{$t('see')}}</button>
                    </a>
                </div>
            </div>
        </el-drawer>
        <div class="modal fs-14" v-if="usermodal">
            <div class="modal-cover"></div>
            <div class="modal-item" style="max-width:500px;">
                <div class="flex justify-between">
                    <p class="modal-title ">{{$t('user_date')}}:</p>
                    <button style="font-size:16px; " @click="usermodal=false"><i class="el-icon-close" style="font-weight:600;"></i> </button>
                </div>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('first_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.name}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('last_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.surname}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('mid_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.middlename}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('phone_munber')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.phone_number}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('user_email')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.profile.email ? userData.profile.email : '-'}}</el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :md="12" class="fw-600 mb-2">{{$t('organisation_name')}}:</el-col>
                    <el-col :md="12" class="mb-2">{{userData.organization && userData.organization.name_cyr ? userData.organization.name_cyr : '-'}}</el-col>
                </el-row>

                <div class="w-full modal-inputes flex justify-end">
                    <button @click="usermodal=false" style="padding:15px 15px;"> <i class="el-icon-close mr-2"></i> {{$t('close')}}</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Moment from 'moment';
import InvestmentSwitch from "@/components/InvestmentSwitch";
import {
    extendMoment
} from 'moment-range';
const moment = extendMoment(Moment);
export default {
    components:{InvestmentSwitch},
    data() {
        return {
            input2: null,
            drawer: false,
            pdf: '',
            namePdf: '',
            loadDawer: false,
            page_count: 0,
            buildsList: [],
            region: '',
            district: '',
            buildinggroup: '',
            current_district: [],
            objInfo: {},
            search: '',
            item_count: 10,
            loading: false,
            current_page: 1,
            current_page1: 1,
            buildingtype: '',
            districts: [],
            usermodal: false,
            userData: {
                organization: {},
                profile: {}
            },
            multiple_add:false,
          loading_generate:false
        };
    },
    computed: {
        me() {
            return this.$store.state.me;
        },
        role() {
            return this.$store.state.role
        },
        regions() {
            return this.$store.state.dictionary.region;
        },
        building_group() {
            return this.$store.state.dictionary.buildinggroup;
        }
    },

    mounted() {
        this.current_page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
        this.search = this.$route.query.search ? this.$route.query.search : ''
        this.region = this.$route.query.region ? parseInt(this.$route.query.region) : ''
        this.district = this.$route.query.district ? parseInt(this.$route.query.district) : ''
        this.buildinggroup = this.$route.query.buildinggroup ? parseInt(this.$route.query.buildinggroup) : ''
        this.item_count = this.$route.query.item_count ? parseInt(this.$route.query.item_count) : 10
        if (this.region != '') {
            this.getDistrict()
        }
        this.__GET();
    },
    methods: {
      generate_building_list(){
        this.loading_generate = true
        let type = 'EXG'
        let offset = this.item_count * (this.current_page - 1);
        let is_invest = true
        axios({
          url: `/building/generate_building_info/?buildingtype=${type}&search=${this.search}&region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&limit=${this.item_count}&offset=${offset}&is_invest=${is_invest}&invest_text=${is_invest}`,
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');

              fileLink.href = fileURL;
              fileLink.setAttribute('download', `Bino-1.xlsx`);
              document.body.appendChild(fileLink);
              fileLink.click();
            })
            .finally(() => {
              this.loading_generate = false
            });
      },
        GeneratePasport(id) {
            this.loading = true
            this.loadDawer = true
            axios.post(`/building/specification/${id}/generate/`)
                .then(res => {
                    this.drawer = true
                    this.pdf = axios.defaults.baseURL.slice(0, -8) + res.data.pdf_link

                })
                .catch(e => {

                })
                .finally(() => {
                    this.loadDawer = false
                    this.loading = false

                })
        },
        downloadFile(file) {
            fetch(file)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download = 'PasportBino' || "file.json";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
        },
        paginate(val) {
            this.current_page =  parseInt(val);
            this.__GET()
        },
        paginateSize(val) {
            this.item_count = val
            this.current_page = 1

            this.__GET();
        },

        __GET() {
            this.loading = true;
            let offset = this.item_count * (this.current_page - 1);
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {

                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
                    .catch(e => {
                        if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                            console.log(e)
                        }
                    });
            } else {
                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}`)
                    .catch(e => {
                        if (e.name !== 'NavigationDuplicated' && !e.message.includes('Avoided redundant navigation to current location')) {
                            console.log(e)
                        }
                    });
            }
            var link = `/building/building_to_invest/?region__shared_id=${this.region}&district=${this.district}&building_group=${this.buildinggroup}&search=${this.search}&limit=${this.item_count}&offset=${offset}`
            axios.get(link)
                .then((response) => {
                    this.buildsList = response.data.results;
                    this.page_count = Math.ceil(response.data.count / this.item_count);
                    this.current_page1 = this.$route.query.page ?parseInt(this.$route.query.page) : parseInt(this.$route.query.page)
                    if(response.data.count<this.item_count && this.current_page!=1){
                        this.current_page=1
                        this.__GET()
                    }
                })
                .finally(() => {
                    this.loading = false
                })

        },
        routeTo(item, a) {
            if (item.buildingtype === "NEW") {
                this.$router.push({
                    path: `/new-build/${item.id}/${a}`,
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        id: item.id
                    },
                });
            } else {
                this.$router.push({
                    path: `/exist-build/${item.id}/${a}`,
                    params: {
                        id: this.$route.params.id
                    },
                    query: {
                        id: item.id
                    },
                });
            }
        },
        getDistrict() {
            this.loading = true;
            axios.get(`/dictionary/district/?region__shared_id=${this.region}`)
                .then((response) => {

                    this.districts = response.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        ChangeDistrict() {
            this.current_page = 1
            this.current_page1 = 1
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {
                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
            } else {
                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}`)
            }
        },
        DownloadExel(id, name) {
            axios({
                url: `/building/generate/${id}/building/`,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', `${name}-Bino-${id}.xlsx`);
                document.body.appendChild(fileLink);

                fileLink.click();
            });
        },
        UserModal(a) {
            if (a != null && a.profile != null) {
                this.userData = a
                this.usermodal = true
            }
        },
        regionChanged() {
            this.current_page = 1
            this.current_page1 = 1
            if (this.search != '' || this.region != '' || this.district != '' || this.buildinggroup != '' || this.item_count != 10) {
                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}&region=${this.region}&district=${this.district}&buildinggroup=${this.buildinggroup}&item_count=${this.item_count}&search=${this.search}`)
            } else {
                this.$router.push(`/investment/${this.$route.params.id_invest}?page=${this.current_page}`)
            }
            this.districts = [];
            this.district = "";
            this.getDistrict();
        },
    }
};
</script>
 